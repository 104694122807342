import React, { Component } from "react"
import { graphql, Link } from "gatsby";
import Img from 'gatsby-image'
import { Helmet } from "react-helmet";
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Layout from "../../components/layout";
import ReactGA from 'react-ga'
import "../../css/style.css";
import "../../css/animate.min.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";
import check from '../../images/check-icon.svg'
import InstituteTabs from "../../components/institutiontabs";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import ScrollAnimation from 'react-animate-on-scroll';
import {Animated} from "react-animated-css";
import PopupForm from "../../components/popupForm";
import cardImg from '../../images/programs/degree.svg'
import cardImg1 from '../../images/programs/start online.svg'
import cardImg2 from '../../images/programs/get your visa.svg'
import cardImg3 from '../../images/programs/completed overseas.svg'
import ReactHtmlParser from 'react-html-parser'


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Countries  extends Component {
    constructor(props){
        super(props);
        this.state ={
            time: 90000000
        }
        
        this.readmoreFucnction = this.readmoreFucnction.bind(this)
        this.readmoreFucnction2 = this.readmoreFucnction2.bind(this)
        this.readmoreFucnction3 = this.readmoreFucnction3.bind(this)     
    }

    componentDidMount(){
        var swiper = new Swiper('.universities-swiper',  {
            slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            
            320: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                spaceBetween: 0,
                observer: true,
                observeParents: true,
            },
         
            480: {
                slidesPerView: 2,
                slidesPerColumn: 1,
                spaceBetween: 20,
                observer: true,
                observeParents: true,
            },
         
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                spaceBetween: 10,
                observer: true,
                observeParents: true,
            }
        }
    });
    var swiper = new Swiper('.universities-swiper',  {
        slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        
        320: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 0,
            observer: true,
            observeParents: true,
        },
     
        480: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
        },
     
        768: {
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
        }
    }
});
var swiper = new Swiper('.universities-swiper',  {
    slidesPerView: 3,
slidesPerColumn: 2,
spaceBetween: 10,
observer: true,
observeParents: true,
pagination: {
    el: '.swiper-pagination',
    clickable: true,
},
breakpoints: {
    
    320: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
    },
 
    480: {
        slidesPerView: 2,
        slidesPerColumn: 1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
    },
 
    768: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
    }
}
});
 

var swiper = new Swiper('.blogs-swiper', {
    slidesPerView:2,
    spaceBetween: 5,
    autoplay: true,
  
    speed: 300,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    breakpoints: {
        
        320: {
            slidesPerView:1,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
     
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
     
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
        }
    }
});


}
readmoreFucnction=()=>{
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction3() {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
    }
    handlePopupWindow = () => {
        this.setState({ time: Math.random() })
    }
  render(){
    const pageData = this.props.data.allWordpressPage.nodes[0].acf
    const uniArrTotal = this.props.data.allWordpressWpUniversities.nodes
    const uniArr = uniArrTotal.filter(uni => {
      const featuredCountryIds = this.props.data.allWordpressPage.nodes[0].acf.featured_universities_country.map(country => country.wordpress_id);

      return featuredCountryIds.some(id => id === uni.wordpress_id);
    });
    

    const post = this.props.data.allWordpressPage.nodes[0];
    const metatitle = post.acf.meta_title ;
    const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 

    const cardData = [
        {
            image: cardImg, title: "Choose Your degree", text: "Get admission to a top US university in a stream of your choice - business, arts, engineering, technology and more"
        },
        {
            image: cardImg1, title: "Start Online", text: "Complete the first year of your degree online in just 10 months (6-8 credits per 7 week study period) and receive a transcript from a US university"
        },
        {
            image: cardImg2, title: "Get Your Visa", text: "Complete your visa process while studying and be ready to go to the US by the end of the All American Year 1 program."
        },
        {
            image: cardImg3, title: "Complete Overseas", text: "Progress to the overseas campus to complete the remainder of your degree and apply for post study work rights."
        }
    ]
    
    return(
        <div clsss="countrie-page">
            <PopupForm time={this.state.time} />
            <Layout>
                <Helmet>
                <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          

                </Helmet>

                <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item">
												<a href="/countries">Countries </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
				</div>
                <section 
                    class="countrie-banner-sec" 
                    id="country-page-banner-section-usa"
                >    
                    <Animated 
                        animationIn="fadeInUp" 
                        animationOut="fadeInDown" 
                        isVisible={true}
                    >  
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="countrie-banner-inner">
                                       
                                        <div class="text-box wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" id="country-banner-image-container">
                                        {(() => {
                                                if(post.acf.custom_h1 != '' && post.acf.custom_h1 != null) {
                                                        return (<h1>{post.acf.custom_h1}</h1> )
                                                    } else {
                                                        return (<h1 class="default">Learn More About Studying In The United Kingdom (UK)</h1> ) 
                                                    }
                                                    })()}
                                          
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>   
                    </Animated> 
                </section>
   
     <section style={{ backgroundColor: "#fff" }} className="py-md-1 country-page country-section1">
        <div className="container country-page-features-main-container">
          <div className="row country-page-feature-container justify-content-center align-items-center">
            <div className="col-md-6">
              <ScrollAnimation
                animateIn='fadeInLeft'
                style={{ 'animation-duration': '1s' }}
              >
                <img
                  src={pageData.section_1_image.source_url}
                  alt="Section 1"
                  style={{
                    borderRadius: "15px",
                    maxWidth: "500px",
                    maxHeight: "600px",
                  }}
                />
              </ScrollAnimation>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ScrollAnimation
                animateIn='fadeInRight'
                style={{ 'animation-duration': '1s' }}
              >
    
          
                  {ReactHtmlParser(pageData.section_1_content)}
             
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

    
      <section className="country-page country-section2">
        <div className="container country-page-features-main-container">
          <div className="row country-page-feature-container justify-content-center align-items-center">
            <div className="col-md-6 order-first order-md-last">
              <ScrollAnimation
                animateIn='fadeInLeft'
                style={{ 'animation-duration': '1s' }}
              >
                <img
                  src={pageData.section_2_image.source_url}
                  alt="Section 2"
                  style={{
                    borderRadius: "15px",
                    maxWidth: "500px",
                    maxHeight: "600px",
                  }}
                />
              </ScrollAnimation>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ScrollAnimation
                animateIn='fadeInRight'
                style={{ 'animation-duration': '1s' }}
              >
           
               
                  {ReactHtmlParser(pageData.section_2_content)}
           
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

    
      <section style={{ backgroundColor: "#fff" }} className="py-md-1 country-page country-section3">
        <div className="container country-page-features-main-container">
          <div className="row country-page-feature-container justify-content-center align-items-center">
            <div className="col-md-6">
              <ScrollAnimation
                animateIn='fadeInLeft'
                style={{ 'animation-duration': '1s' }}
              >
                <img
                  src={pageData.section_3_image.source_url}
                  alt="Section 3"
                  style={{
                    borderRadius: "15px",
                    maxWidth: "500px",
                  }}
                />
              </ScrollAnimation>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ScrollAnimation
                animateIn='fadeInRight'
                style={{ 'animation-duration': '1s' }}
              >
           
               
                  {ReactHtmlParser(pageData.section_3_content)}
                
                <a
                  className="btn-default visa-visit-btn mx-auto"
                  href={pageData.section_3_button_link}
                  target="_blank"
                                    onClick={() => {
                                        this.handlePopupWindow()
                                        ReactGA.event({
                                            category: "Countries",
                                            action: "Clicks",
                                            label: "Visit Visa Website - Our Visa Guidance for UK"
                                        })
                                    }}
                >
                  {pageData.section_3_button_text}
                </a>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

    
      <section className="country-page country-section4">
        <div className="container country-page-features-main-container">
          <div className="row country-page-feature-container justify-content-center align-items-center">
            <div className="col-md-6 order-first order-md-last">
              <ScrollAnimation
                animateIn='fadeInLeft'
                style={{ 'animation-duration': '1s' }}
              >
                <img
                  src={pageData.section_4_image.source_url}
                  alt="Section 4"
                  style={{
                    borderRadius: "15px",
                    maxWidth: "500px",
                    maxHeight: "600px",
                  }}
                />
              </ScrollAnimation>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ScrollAnimation
                animateIn='fadeInRight'
                style={{ 'animation-duration': '1s' }}
              >
               
               
                  {ReactHtmlParser(pageData.section_4_content)}
               
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      
      <section style={{ backgroundColor: "#fff" }} className="py-md-1 country-page country-section5">
        <div className="container country-page-features-main-container">
          <div className="row country-page-feature-container-last justify-content-center">
            <div className="col-md-6">
              <ScrollAnimation
                animateIn='fadeInLeft'
                style={{ 'animation-duration': '1s' }}
              >
                <h3 className="mb-2 mb-md-4 text-center pt-4 pt-md-0">
                  {ReactHtmlParser(pageData.section_5_title)}
                </h3>
                <img
                  src={pageData.section_5_image_1.source_url}
                  alt="Mount Saint Mary's University"
                  className="img-fluid mb-3 d-flex mx-auto shadow p-1"
                  style={{ borderRadius: "15px", width: "250px" }}
                />
          {pageData.section_5_image_2 && (
  <img
    src={pageData.section_5_image_2?.source_url}
    alt="Tiffin University"
    className="img-fluid mb-3 d-flex mx-auto shadow p-1"
    style={{ borderRadius: "15px", width: "250px" }}
  />
)}

              </ScrollAnimation>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ScrollAnimation
                animateIn='fadeInRight'
                style={{ 'animation-duration': '1s' }}
              >
            
             
                  {ReactHtmlParser(pageData.section_5_content)}
               
                <a
                  className="btn-default visa-visit-btn mx-auto"
                  href={pageData.section_5_button_link}
                  target="_blank"
                                    onClick={() => {
                                        this.handlePopupWindow()
                                        ReactGA.event({
                                            category: "Countries",
                                            action: "Clicks",
                                            label: "Visit Visa Website - Our Visa Guidance for UK"
                                        })
                                    }}
                >
                  {pageData.section_5_button_text}
                </a>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

       
                    <div class="container" style={{ backgroundColor: "#f8f8f8" }}>
                        <div class="row">
                            <div class="col-12">
                              
                                    <h2 className="text-center mb-0 mt-md-4">{pageData.featured_universities_title}</h2>
                                
                                <Tabs>
                                    <TabPanel>
                                        <section id="marzen" class="tab-panel">
                                            <div class="interest-explore-inner">
                                                <div class="swiper-container universities-swiper">
                                                <div className="swiper-wrapper row justify-content-center m-0">
    {
        uniArr.map(university => {
            const bannerImage = university.acf.banner_image ? university.acf.banner_image.source_url : (university.acf.university_thumbnail ? university.acf.university_thumbnail.source_url : null);


            const hasDetailPage = Array.isArray(university.acf.university_detail_page) && university.acf.university_detail_page.some(page => page.toLowerCase() === "yes");

            const websiteLink = university.acf.website_url ? university.acf.website_url : null;

            return (
                <div className="col-md-4" key={university.id}>
                    {
                        hasDetailPage ? (
                            <Link to={`/university-detail/${university.slug}`}>
                                <div className="swiper-slide" value="Open" onClick={() => {
                                    ReactGA.event({
                                        category: "Institutions",
                                        action: "Clicks",
                                        label: `UK - ${university.title}`
                                    })
                                }}>
                                    <InstituteTabs
                                        ImageUrl={bannerImage}
                                        UniversityName=""
                                    />
                                </div>
                            </Link>
                        ) : websiteLink ? (
                            <a href={websiteLink} target="_blank" rel="noopener noreferrer">
                                <div className="swiper-slide" value="Open" onClick={() => {
                                    ReactGA.event({
                                        category: "Institutions",
                                        action: "Clicks",
                                        label: `External Website - ${university.title}`
                                    })
                                }}>
                                    <InstituteTabs
                                        ImageUrl={bannerImage}
                                        UniversityName=""
                                    />
                                </div>
                            </a>
                        ) : (
                            <div className="swiper-slide" value="No Link">
                                <InstituteTabs
                                    ImageUrl={bannerImage}
                                    UniversityName=""
                                />
                            </div>
                        )
                    }
                </div>
            )
        })
    }
</div>

                                                </div>
                                            </div>
                                        </section>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                

            </Layout>
        </div>     
    )
  }
}
export default Countries;

export const WorldGradUniversities = graphql`
    {
allWordpressPage(filter: {wordpress_id: {in: 4949}}) {
      nodes {
        title
        wordpress_id
        acf {
        meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
custom_h1
          section_1_image {
            source_url
          }
          section_1_content
          section_2_image {
            source_url
          }
          section_2_content
          section_3_image {
            source_url
          }
          section_3_content
          section_3_button_text
          section_3_button_link
          section_4_image {
            source_url
          }
          section_4_content
          section_5_title
          section_5_image_1 {
            source_url
          }
     
          section_5_content
          section_5_button_text
          section_5_button_link
          featured_universities_title
          featured_universities_country {
  wordpress_id
}
        }
      }
    } 
        allWordpressWpUniversities(filter: {acf: {country: {eq: "United Kingdom"}}}) {
            nodes {
                wordpress_id
                slug
                title
                acf {
                 university_detail_page
                 website_url

                    university_thumbnail {
                        source_url
                    }
                    banner_image {
						source_url
					}
                }
            }
        }

    }
`