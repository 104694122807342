import React, { Component } from "react";
import "../css/style.css"
import ReactHtmlParser from 'react-html-parser';

class InstituteTabs extends Component {
  


render(){
  return(

    <div class="image-holder wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
    <a href={this.props.url}>
        <img alt={this.props.UniversityName} src={this.props.ImageUrl}/>
        {this.props.staricon ? 
        <div class="star-icon star-icon2">
            <img src={this.props.staricon} alt="Star Icon"/> 
        </div>
        :""}
        <h3><div>{ReactHtmlParser(this.props.UniversityName)}<br/></div></h3>
    </a>
    </div>
  )
}

}

export default InstituteTabs;